import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Comments from '../../components/Comments';
import FAQ from '../../components/FAQ';
import ReactGA from 'react-ga4';

function NewsletterView() {
  const params = useParams();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [htmlContent, setHtmlContent] = useState('');
  const [cssContent, setCssContent] = useState('');
  const [keywords, setKeywords] = useState('');
  const [lastUpdated, setLastUpdated] = useState('');
  const [author, setAuthor] = useState('');
  const [question1, setQuestion1] = useState('');
  const [answer1, setAnswer1] = useState('');
  const [question2, setQuestion2] = useState('');
  const [answer2, setAnswer2] = useState('');
  const [question3, setQuestion3] = useState('');
  const [answer3, setAnswer3] = useState('');
  
  const titleParam = encodeURIComponent(params.keywords.replace(/-/g, ' '));

  useEffect(() => {
    const fetchData = async () => {
      try {
        const encodedTitle = encodeURIComponent(params.keywords.replace(/-/g, ' '));
        const response = await fetch(`https://modelo-de-negocios-backend-180g.onrender.com/blogs/${encodedTitle}`);
        const data = await response.json();

        console.log('Fetched Data:', data);

        setTitle(data.title);
        setDescription(data.description);
        setKeywords(data.keywords);
        setHtmlContent(decodeHtml(data.html));
        setCssContent(decodeCss(data.css));
        setLastUpdated(data.lastUpdated);
        setAuthor(data.author);
        setQuestion1(data.question1);
        setAnswer1(data.answer1);
        setQuestion2(data.question2);
        setAnswer2(data.answer2);
        setQuestion3(data.question3);
        setAnswer3(data.answer3);

        ReactGA.send({
          hitType: 'pageview',
          page: window.location.pathname,
          title: data.title,
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    if (params.keywords) {
      fetchData();
    }
  }, [params.keywords]);

 // Generar datos estructurados JSON-LD para la entrada del blog
 useEffect(() => {
  if (question1 || question2 || question3) {
    const blogStructuredData = {
      "@context": "http://schema.org",
      "@type": "BlogPosting",
      "headline": title,
      "description": description,
      "keywords": keywords,
      "datePublished": lastUpdated,
      "author": {
        "@type": "Person",
        "name": author
      },
      // Incluir FAQ dentro de la estructura del blog si existen preguntas
      "mainEntityOfPage": {
        "@type": "FAQPage",
        "mainEntity": [
          ...(question1 ? [{
            "@type": "Question",
            "name": question1,
            "acceptedAnswer": {
              "@type": "Answer",
              "text": answer1
            }
          }] : []),
          ...(question2 ? [{
            "@type": "Question",
            "name": question2,
            "acceptedAnswer": {
              "@type": "Answer",
              "text": answer2
            }
          }] : []),
          ...(question3 ? [{
            "@type": "Question",
            "name": question3,
            "acceptedAnswer": {
              "@type": "Answer",
              "text": answer3
            }
          }] : [])
        ]
      }
    };

    const scriptBlog = document.createElement('script');
    scriptBlog.type = 'application/ld+json';
    scriptBlog.textContent = JSON.stringify(blogStructuredData);
    document.head.appendChild(scriptBlog);

    return () => {
      document.head.removeChild(scriptBlog);
    };
  }
}, [title, description, keywords,  lastUpdated, author, question1, question2, question3, answer1, answer2, answer3]);

  const decodeHtml = (html) => {
    const txt = document.createElement('textarea');
    txt.innerHTML = html;
    return txt.value;
  };

  const decodeCss = (css) => {
    return css.replace(/\\(["'\\])/g, '$1');
  };

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <meta name="lastUpdated" content={lastUpdated} />
        <meta name="author" content={author} />
        <meta httpEquiv="Content-Language" content="es" />
        <style>{cssContent}</style>
      </Helmet>
      <Header />
      <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
      {(question1 || question2 || question3) && (
        <FAQ 
          question1={question1} answer1={answer1} 
          question2={question2} answer2={answer2} 
          question3={question3} answer3={answer3}
        />
      )}
      <Comments title={titleParam} />
      <Footer />
    </>
  );
}

export default NewsletterView;
